import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import { 
    USER_OFFER_TERMINATE_REASON_OTHER,
} from 'Consts/userOffers';

import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class AdeptUserOfferConfirmation extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            cancel: PropTypes.func.isRequired,
            list: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
        onClose: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        reason: '',
        otherReason: null,
        showInput: false,
    };
    
    onReasonChange = reason => {
        if(fromSelectObject(reason).value === USER_OFFER_TERMINATE_REASON_OTHER) {
            this.setState({ showInput: true, reason });
        } else {
            this.setState({ showInput: false, reason });
        }
    }

    onCancel = () => {
        const { actions, data, location, history, onClose } = this.props;
        const { otherReason } = this.state;
        
        actions.cancel({ 
            id: data.id,
            reason: otherReason,
        }) 
            .then(() => {
                if (onClose) {
                    onClose();
                }
                return history.push(location.pathname);
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptUserOffersEditor] Failed to paymnet', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    otherReason = (value) => {
        this.setState({ otherReason: value });
    }

    render() {
        const { data } = this.props;
        const { otherReason } = this.state;
        
        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-offers-editor"
                styles={require('./styles')}
            >
                <p className="description">Jeśli wypowiesz zamówienie, to okres wypowiedzenia zostanie rozpoczęty. Jeśli Twoje zamówienie nie ma okresu wypowiedzenia, to zostanie od razu anulowane. </p>
                <Input
                    label="Powód"
                    name="otherReason"
                    placeholder="Podaj swój powód"
                    type="text"
                    styleVersion={2}
                    className="other-reason"
                    onChange={({ value }) => this.otherReason(value)}
                    value={otherReason}
                />
                <Button
                    onClick={this.onCancel}
                    style='gradient'    
                > 
                    Wypowiedz zamówienie
                </Button>
            </StyledComponent>
        );
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { PUBLIC_SHOP_USER_OFFER, PUBLIC_SHOP } from 'Consts/routes';
import { USER_OFFER_STATUS_NEW, USER_OFFER_STATUS_ACTIVE, USER_OFFER_LABEL_VALUE_NOTICE, USER_OFFER_USERS_TYPE_MULTI } from 'Consts/userOffers';
import { OFFER_PERIOD_TYPE_SINGLE, OFFER_PERIOD_TYPE_RECURRING } from 'Consts/offers';
import { PRODUCT_CATEGORY_SHOP_MAP } from 'Consts/products';

import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getStatusLabel } from 'Utils/userOffer';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import TextBox from 'Components/layout/panel/TextBox';
import Button from 'Components/layout/Button';
import UserOfferConfirmation from 'Components/adept/modals/UserOfferConfirmation';
import UserOfferActivation from 'Components/adept/modals/UserOfferActivation';
import UserOfferCancellation from 'Components/adept/modals/UserOfferCancellation';
import UserOfferRemoval from 'Components/adept/modals/UserOfferRemoval';
import UserOfferInvite from 'Components/adept/modals/UserOfferInvite';

export default class AdeptUserOfferEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            cancel: PropTypes.func.isRequired,
            payment: PropTypes.func.isRequired,
            list: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedQuery: {},
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
        toConfirm: false,
        payment: false,
        cancel: false,
        remove: false,
        active: false,
        invite: false,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    render() {
        const { data, location, history } = this.props;
        const { formState, toConfirm, payment, cancel, remove, active, invite } = this.state;

        if (!formState) {
            return (<Spinner />);
        }
        
        const productCategoryMapElem = PRODUCT_CATEGORY_SHOP_MAP.find(productCategoryShopElem => productCategoryShopElem.productCategorySlug  === data?.productCategory?.slug);

        let canBeActive = false;
        if(data.status === USER_OFFER_STATUS_ACTIVE && data.activeTo) {
            canBeActive = true;
        }
        
        let canBeChanged = false;
        if(productCategoryMapElem
            && data.periodType === OFFER_PERIOD_TYPE_RECURRING 
            && (data.status === USER_OFFER_STATUS_ACTIVE || data.status == USER_OFFER_STATUS_NEW)
        ) {
            canBeChanged = true;
        }
        
        let canBePaid = false;
        if(data.periodType === OFFER_PERIOD_TYPE_SINGLE && data.status == USER_OFFER_STATUS_NEW) {
            canBePaid = true;
        }
        if(data.periodType === OFFER_PERIOD_TYPE_RECURRING && (data.status == USER_OFFER_STATUS_NEW || data.status == USER_OFFER_STATUS_ACTIVE)) {
            canBePaid = true;
        }

        let canBeCanceled = false;
        if(data.periodType === OFFER_PERIOD_TYPE_RECURRING && data.status == USER_OFFER_STATUS_ACTIVE && getStatusLabel(data).value !== USER_OFFER_LABEL_VALUE_NOTICE) {
            canBeCanceled = true;
        }

        let canBeRemoved = false;
        if(data.status == USER_OFFER_STATUS_NEW) {
            canBeRemoved = true;
        }
    
        return (
            <StyledComponent
                className="adept-user-offers-editor"
                styles={require('./styles')}
            >
                <div className="block block-left" style={{ backgroundImage: `url(${require('Theme/images/shop/shop_offer_old.jpg')})` }} />
                <div className="block block-right">
                    <ViewHeader 
                        title={data.name}
                        subtitle={data.offer && data.offer.description}
                        styleVersion={2}
                    />
                    <div className="block-wrapper">
                        <div className="products-block">
                            <h2 className="title-block">Produkty: </h2>
                            <div className="boxes-container">
                                {data.products.map(element => 
                                    <TextBox 
                                        key={element.id}
                                        title={element.product && element.product.name}
                                        subtitle={`x${element.quantity}`}
                                        styleVersion={3}
                                        stateColor='info'
                                    />
                                )}
                            </div>
                        </div>
                        <div className="details-block">
                            <h2 className="title-block">Dane zamówienia: </h2>
                            <div className="boxes-container">
                                <TextBox 
                                    title="Najbliższa data płatności"
                                    subtitle={getFormattedDate(data.paidTo, 'date')}
                                    visible={Boolean(data.paidTo)}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/wallet.svg'),
                                    }}
                                    styleVersion={2}
                                />
                                <TextBox 
                                    title="Status"
                                    subtitle={getStatusLabel(data).label}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/complete_workout.svg'),
                                    }}
                                    styleVersion={2}
                                    stateColor={getStatusLabel(data).stateColor}
                                />
                                <TextBox 
                                    title="Cena"
                                    subtitle={formatPrice(data.totalPriceGross)}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/paymentMethods.svg'),
                                    }}
                                    styleVersion={2}
                                />
                                {data.firstPayment  && (
                                    <TextBox 
                                        title="Data zakupu"
                                        subtitle={getFormattedDate(data.firstPayment && data.firstPayment.createdAt, 'date')}
                                        image={{
                                            isVisible: true,
                                            url: require('Theme/images/icons/calendar.svg'),
                                        }}
                                        styleVersion={2}
                                        stateColor={''}
                                    />
                                )}
                                <TextBox 
                                    title="Koniec okresu wypowiedzenia"
                                    subtitle={getFormattedDate(data.activeTo, 'date')}
                                    visible={Boolean(data.activeTo)}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/calendar.svg'),
                                    }}
                                    styleVersion={2}
                                />
                                <TextBox 
                                    title="Koniec okresu obsługi zamówienia"
                                    subtitle={getFormattedDate(data.terminatedTo, 'date')}
                                    visible={Boolean(data.terminatedTo)}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/calendar.svg'),
                                    }}
                                    styleVersion={2}
                                />
                                <TextBox 
                                    title="Otwarte do"
                                    subtitle={getFormattedDate(data.openTo)}
                                    visible={Boolean(data.openTo)}
                                    image={{
                                        isVisible: true,
                                        url: require('Theme/images/icons/calendar.svg'),
                                    }}
                                    styleVersion={2}
                                />
                            </div>
                            <div className="controls-block"> 
                                {canBePaid && data.isOwner && data.canBePaid && (
                                    <NavLink
                                        to={withVariables(PUBLIC_SHOP_USER_OFFER.path, { id: data.id })}
                                        className="button-navlink"
                                    >
                                        <Button 
                                            className="button user-offers-editor-onpayment"
                                            style="gradient"
                                        >
                                            Opłać
                                        </Button>
                                    </NavLink>
                                )}
                                {canBeChanged && data.isOwner && (
                                    <NavLink
                                        className="button-navlink"
                                        to={withVariables(
                                            productCategoryMapElem.shopPath,
                                            {},
                                            { userOfferId: data.id }
                                        )}
                                    >
                                        <Button
                                            className="button user-offers-editor-payment-cancel"
                                            style="gradient"
                                        >
                                            Zmień zamówienie
                                        </Button>
                                    </NavLink>
                                )}
                                {canBeCanceled && data.isOwner && (
                                    <Button
                                        className="button user-offers-editor-payment-cancel"
                                        onClick={() => this.setState({ toConfirm: true, cancel: true })}
                                        style="gradient"
                                    >
                                        Wypowiedz zamówienie
                                    </Button>
                                )}
                                {canBeActive && data.isOwner && (
                                    <Button
                                        className="button user-offers-editor-payment-cancel"
                                        onClick={() => this.setState({ toConfirm: true, active: true })}
                                        style="gradient"
                                    >
                                        Wycofaj wypowiedzenie
                                    </Button>
                                )}
                                {canBeRemoved && data.isOwner && (
                                    <Button
                                        className="button user-offers-editor-payment-remove"
                                        onClick={() => this.setState({ toConfirm: true, remove: true })}
                                        style="gradient"
                                    >
                                        Usuń zamówienie
                                    </Button>
                                )}
                                {data.isOwner && data.usersType === USER_OFFER_USERS_TYPE_MULTI && (
                                    <Button
                                        className="button user-offers-invite-user"
                                        onClick={() => this.setState({ toConfirm: true, invite: true })}
                                        style="gradient"
                                    >
                                        Zaproś użytkownika
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <UserOfferConfirmation
                    location={location}
                    history={history}
                    isOpen={Boolean(toConfirm && payment )}
                    onClose={() => this.setState({ toConfirm: false, payment: false })}
                    data={{ ...formState, payment }}
                />
                <UserOfferCancellation
                    location={location}
                    history={history}
                    isOpen={Boolean(toConfirm && cancel)}
                    onClose={() => this.setState({ toConfirm: false, cancel: false })}
                    data={{ ...formState, cancel }}
                />
                <UserOfferActivation
                    location={location}
                    history={history}
                    isOpen={Boolean(toConfirm && active )}
                    onClose={() => this.setState({ toConfirm: false, active: false })}
                    data={{ ...formState, active }}
                />
                <UserOfferRemoval
                    location={location}
                    history={history}
                    isOpen={Boolean(toConfirm && remove)}
                    onClose={() => this.setState({ toConfirm: false, remove: false })}
                    data={{ ...formState, remove }}
                />
                <UserOfferInvite
                    location={location}
                    history={history}
                    isOpen={Boolean(toConfirm && invite)}
                    onClose={() => this.setState({ toConfirm: false, invite: false })}
                    data={{ ...formState, invite }}
                />
            </StyledComponent>
        );
    }
}

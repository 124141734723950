import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';

export default class AdeptUserOfferActivation extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            activate: PropTypes.func.isRequired,
        }).isRequired,
        onClose: PropTypes.func,
        data: PropTypes.object.isRequired,
    };
    
    static defaultProps = {
        data: null,
    };
    
    state = {
        isLoading: false,
    };

    onActivate = () => {
        const { actions, data, onClose, history, location } = this.props;

        this.setState({
            isLoading: true,
        });
        
        actions.activate({ 
            id: data.id,
        })
            .then(response => {
                if (onClose) {
                    onClose();
                }

                return history.push(location.pathname);
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptUserOfferActivation] Failed to activate', error);
                this.setState({
                    isPending: false,
                });
            });
    }

    render() {
        const { data } = this.props;

        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-offers-editor"
                styles={require('./styles')}
            > 
                <p className="description">Wycofaj wypowiedzenie, aby dalej korzystać z zamówienia.</p>
                <Button 
                    onClick={this.onActivate}
                    style='gradient'
                > 
                    Wycofaj wypowiedzenie 
                </Button>
            </StyledComponent>
        );
    }
}
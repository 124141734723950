import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import OfferCancellation from 'Components/adept/userOffers/Cancellation';

export default class AdeptModalUserOfferCancellation extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: null,
    };
    state = {};

    render() {
        const { location, history, isOpen, onClose, data } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="adept-modal-user-offer-cancellation" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title='Czy na pewno chcesz wypowiedzieć zamówienie?' />
                        <OfferCancellation
                            location={location}
                            history={history}
                            data={data}
                            onClose={onClose}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}

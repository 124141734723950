import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 60vw;

    .adept-user-offers-editor {
        display: flex;
        flex-direction: column;

        .description {
            padding-top: 1em;
            padding-bottom: 2em;
            font-size: 1.2em;
            font-weight: 300;
            color: white;

            b {
                font-weight: 700
            }
        }
    }
`;

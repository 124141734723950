import { connect } from 'react-redux';
import Component from './component';

import {
    stats,
} from 'Redux/modules/adept/events/actions';

export default connect(
    state => ({
        stats: state.adeptEvents.stats,
    }),
    dispatch => ({
        actions: {
            stats: dispatch(stats),
        },
    })
)(Component);

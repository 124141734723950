import { connect } from 'react-redux';
import Component from './component';

import {
    cancel,
    payment,
} from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            cancel: dispatch(cancel),
            payment: dispatch(payment),
        },
    }),
)(Component);
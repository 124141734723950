import { connect } from 'react-redux';
import Component from './component';

import {
    removeUser,
    getSubUsers,
} from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({
        subUsers: state.adeptUserOffers.subUsers,
    }),
    dispatch => ({
        actions: {
            removeUser: dispatch(removeUser),
            getSubUsers: dispatch(getSubUsers),
        },
    }),
)(Component);
import { css } from '@emotion/core';

export default (props, state) =>
    css`
    .admin-element-editor {
        .editor-wrapper {
            display: block !important;

            .forms-wrapper {
                width: 100% !important;
                padding: 0 !important;

                .form-wrapper {
                    border: 0;
                }
            }
        }
    }
`;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADEPT_USER_OFFER } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdeptUserOfferInvite extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            invite: PropTypes.func.isRequired,
        }).isRequired,
        onClose: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
    };

    onSubmit = () => {
        const { actions, onClose, data, history } = this.props;
        const { formState } = this.state;

        return actions.invite({
            ...formState,
            id: data.id,
        })
            .then(() => {
                if (onClose) {
                    onClose();
                }

                toast('Użytkownik został zaproszony do zamówienia');

                history.push(
                    withVariables(
                        ADEPT_USER_OFFER.path,
                        { id: data.id }
                    )
                );
            });
    }

    render() {
        const { data, location, history } = this.props;
        const { formState } = this.state;

        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-offers-invite"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => this.setState({ formState }),
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Imię',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'surname',
                            label: 'Nazwisko',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'email',
                            label: 'Adres e-mail',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
        .admin-panel-text-box {
            margin-right: 1em;
            margin-bottom: 1em;
            width: 48% !important;

            .admin-panel-box {
                .box-wrapper {
                    .right-block {
                        .subtitle {
                            font-size: 2em;
                        }
                    }
                }
            }
        }

        @media (min-width: ${variables.tabletL}) {
            .admin-panel-text-box {
                width: 23%;
            }
        }

        @media (min-width: ${variables.mobileL}) and (max-width: ${variables.tabletL}) {
            .admin-panel-text-box {
                width: 30.9%;
        }

    `;

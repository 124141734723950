import { connect } from 'react-redux';
import Component from './component';

import {
    invite,
} from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            invite: dispatch(invite),
        },
    }),
)(Component);
import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;

        .block-left {
            overflow: hidden;
            position: relative;
            margin-right: 4em;
            width: 30%;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .block-right {
            width: 100%;
            .panel-view-header {
                .header {
                    margin-bottom: 3em;
                }
            }

            .boxes-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 2em;

                .admin-panel-text-box {
                    width: 31%;
                    padding: 1em 0;
                    margin-right: 1em;
                    margin-bottom: 1em;
                }
            }

            .products-block, .details-block {
                .title-block {
                    font-size: 1.3em;
                    font-weight: 700;
                    color: white;
                    text-transform: uppercase;
                    margin-bottom: 1em;
                }
            }

            .details-block {
                width: 100%;

                .controls-block {
                    display: flex;
                    
                    .button {
                        margin-right: 1em;
                    }
                }
            }
        }
        
        .reason-select {
            margin-bottom: 2em;
        }

        .other-reason {
            margin-bottom: 2em;
        }
    `;

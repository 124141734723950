import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { getFullName, getActiveStateLabel } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElement';

export default class AdeptUserOfferInvitedUsersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            removeUser: PropTypes.func.isRequired,
            getSubUsers: PropTypes.func.isRequired,
        }).isRequired,
        predefinedQuery: PropTypes.object,
        subUsers: PropTypes.object,
        onMapLabels: PropTypes.func,
    };
    static defaultProps = {
        predefinedState: {},
        onMapLabels: (elem, results) => results,
    };

    state = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        id: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    loadData = () => {
        const { actions, predefinedQuery } = this.props;

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.getSubUsers({ id: predefinedQuery.userOfferId });
        }, 500);
    }

    componentDidMount = () => {
        this.loadData();
    }

    onRemoveUser = (element) => {
        const { actions, predefinedQuery } = this.props;

        return actions.removeUser({ id: predefinedQuery.userOfferId, userId: element.id })
            .then(response => {
                toast('Użytkownik został usunięty z zamówienia');
                this.loadData();
            });
    }

    render() {
        const { location, history, subUsers, onMapLabels } = this.props;
      
        return (
            <StyledComponent
                className="adept-user-offer-invited-users-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={subUsers}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getFullName(element).label}
                            labels={onMapLabels(element, [{
                                isVisible: true,
                                label: getActiveStateLabel(element).label,
                                state: getActiveStateLabel(element).stateColor,
                            }])}
                            controls={[{
                                type: 'button',
                                label: 'Usuń',
                                style: 'gradient',
                                visible: true,
                                onClick: () => this.onRemoveUser(element)
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}